import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import '../styles/style.scss'

import Header from './header'
import Footer from './footer'

import CookieConsent from 'react-cookie-consent'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <CookieConsent
        location="bottom"
        buttonText="Anladım"
        cookieName="gatsby-gdpr"
        expires={10}
        style={{ background: '#1f1f1f' }}
        buttonStyle={{
          color: 'black',
          fontSize: '15px',
          background: 'white',
          padding: '15px',
        }}
        contentStyle={{ color: 'white', fontSize: '13px' }}
      >
        İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı
        deneyimini geliştirebilmek için internet sitemizde çerezler
        kullanılmaktadır. Çerez kullanımını kabul edebilir, ayarlarınızdan
        çerezleri silebilir veya engelleyebilirsiniz. Çerezler hakkında detaylı
        bilgi almak için{' '}
        <Link style={{ color: 'white' }} to="/legal/">
          Çerez ve Cookie Aydınlatma Metni
        </Link>
        'ni incelemenizi rica ederiz.
      </CookieConsent>
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
