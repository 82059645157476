import * as React from 'react'
import Logo from '../images/logo.svg'

import { Link } from 'gatsby'

const Footer = () => (
  <footer>
    <div className="container">
      <div className="contact">
        <div className="text">bize ulaşın</div>
        <div className="socials">
          <div className="mail">
            <a href="mailto:hello@bmcreative.works">hello@bmcreative.works</a>
          </div>
          <div className="instagram">
            <a href="https://bmcw.link/i">instagram</a>
          </div>
        </div>
      </div>
      <div className="alt">
        <Link to="/">©2022 BMCW</Link>
        <img src={Logo} alt="BM Creative Works Logo" />
        <Link to="/legal/">cookies</Link>
      </div>
    </div>
  </footer>
)

export default Footer
